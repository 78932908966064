import { styled } from '@linaria/react';
import { memo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getStraightPath } from 'reactflow';
import { Stages, WorkflowStatus } from '../../../api/process-client/process-client.type';
import { newCOLORS } from '../../../styles/colors';
import { ProcessContext } from '../context/process.context';
import { getAnimatedDotRadius } from '../helpers/edges.helpers';
import { getSearchParams } from '../process.helpers';
import { useProcessScope } from '../process.hooks';

const StageEdge = memo(({ data, sourceX, sourceY, targetX, targetY, style = {}, markerEnd }: EdgeProps) => {
  const navigate = useNavigate();
  const { team, board, taskType, startDate, endDate } = useProcessScope();
  const { setWorkflow } = useContext(ProcessContext);

  const [edgePath, labelX, labelY] = getStraightPath({
    sourceX,
    sourceY: sourceY - 5,
    targetX,
    targetY: targetY + 3,
  });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <circle r={getAnimatedDotRadius(style.strokeWidth)} fill={style.color}>
        <animateMotion dur="2s" repeatCount="indefinite" path={edgePath} begin={`${Math.random() * 2}s`} />
      </circle>
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(10%, -50%) translate(${labelX + 30}px,${labelY}px)`,
            pointerEvents: 'all',
            cursor: 'pointer',
          }}
          className="nodrag nopan"
          onClick={() => {
            const workflow = {
              source: data.sourceStage,
              destination: data.destinationStage,
              status: WorkflowStatus.Expected,
              mapping: data.mapping,
            };

            setWorkflow(workflow);

            const params = getSearchParams(workflow, team?.id || null, board?.id || null, taskType, startDate, endDate);

            if (params) {
              navigate({
                pathname: `/application/process/task-details`,
                search: `?${params}`,
              });
            }
          }}
        >
          <EdgeLabel data={data} />
        </div>
      </EdgeLabelRenderer>
    </>
  );
});

const EdgeLabel = ({ data }: { data: EdgeProps['data'] }) => {
  const borderColor = data.sourceStage === Stages.Blocked ? newCOLORS.purple2 : newCOLORS.black2;
  const bgColor = data.sourceStage === Stages.Blocked ? newCOLORS.white : newCOLORS.black2;
  const textColor = data.sourceStage === Stages.Blocked ? newCOLORS.purple2 : newCOLORS.white;

  return (
    <Label borderColor={borderColor} bgColor={bgColor} textColor={textColor}>
      <Text>{data.value}</Text>
    </Label>
  );
};

const Label = styled.div<{ borderColor: string; bgColor: string; textColor: string }>`
  width: 48px;
  height: 14px;
  padding-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${({ borderColor }) => borderColor};
  background: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};
`;

const Text = styled.div`
  font-family: Figtree;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
`;

export { EdgeLabel, StageEdge };
