import { Icon } from '@iconify/react';
import { TextInput } from '@mantine/core';
import * as Sentry from '@sentry/browser';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { checkDomain, login, resetPassword as sendResetPasswordLink, signup } from '../../api/auth-client/auth-client';

import { fetchUserInfo } from '../../api/user-client/user-client';
import { UserInfo } from '../../api/user-client/user-client.type';
import { UserContext } from '../../contexts/user';
import { checkValidEmail, getEmailDomain } from '../../helpers';
import { trackEvent } from '../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../helpers/analytics-event/analytics-event.type';
import {
  clearAuthenticationStore,
  getRefreshToken,
  refreshAuthenticationStore,
  storeAuthenticationObject,
} from '../../helpers/storage/storage';
import { newCOLORS } from '../../styles/colors';

import { refreshToken } from '../../api/auth-client/auth-client';
import { BackendError } from '../../api/auth-client/auth-client.type';
import { setCredentials, setSignUpStep } from '../../store/auth-store/auth-store.actions';
import { SignUpStep } from '../../store/auth-store/auth-store.type';
import { icons } from './assets';
import styles from './sign-in.module.css';
import { ViewName } from './sign-in.type';
import {
  ResetPasswordView,
  SendResetPasswordLinkView,
  SentResetPasswordLinkView,
  SignInView,
  SignUpView,
} from './views';

export const ErrorMessages = {
  CredentialsRequired: 'Please provide an email and password.',
  ValidEmailRequired: 'Please provide a valid email.',
  InvalidUser: 'Invalid Authentication Provided. Please contact your administrator.',
  InvalidResponse: 'Invalid Response From Provider. Please contact your administrator.',
  InvalidCredentials: 'The email / password combination provided is invalid.',
  Default: 'Something went wrong.',
  ShortPassword: 'This password is too short. It must contain at least 8 characters.',
  PasswordMismatch: 'Passwords do not match.',
};

const showNewOnboarding = import.meta.env.VITE_FEATURE_FLAG_ONBOARDING_V2 === 'true';

export function SignIn({ view }: { view?: ViewName }) {
  const { setUser } = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [shouldShowPassword, setShouldShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [currentView, setCurrentView] = useState<ViewName>(view || ViewName.SignIn);
  const navigate = useNavigate();

  const userInfoQuery = useQuery(['userInfo'], () => fetchUserInfo(), {
    enabled: false,
    onSuccess: (user: UserInfo) => {
      setUser(user);
      Sentry.setUser({ email: user.email || '' });
      Sentry.setContext('user', user);

      if (showNewOnboarding && !user.terms_and_conditions_accepted) {
        setSignUpStep(SignUpStep.TermsAndConditions);
        navigate('/sign-up', { replace: true });
      } else if (showNewOnboarding && !user.organizations?.length) {
        setSignUpStep(SignUpStep.CreateOrganization);
        navigate('/sign-up', { replace: true });
      } else if (user.subscription_status == 'unpaid') {
        navigate(user.organizations.length ? '/application/integrations' : '/application/setup');
      } else {
        navigate(user.organizations.length ? `/application/strategy` : '/application/setup');
      }
    },
    onError: () => setErrorMessage(ErrorMessages.InvalidCredentials),
  });

  const token = getRefreshToken();
  useQuery(['checkIfLoggedIn', token], () => refreshToken(token || ''), {
    enabled: !!token,
    onSuccess: (data) => {
      refreshAuthenticationStore(data.access, data.refresh);
      setIsSignedIn(true);
    },
    onError: clearAuthenticationStore,
  });

  // Read tokens or error from URL; if tokens exist, set auth, remove and redirect
  // If neither tokens nor errors exist, check to see if the user is already logged in.
  useEffect(() => {
    const refresh = searchParams.get('refresh');
    const access = searchParams.get('access');
    const error = searchParams.get('error');
    if (error) {
      switch (error) {
        case 'invalid_user':
          setErrorMessage(ErrorMessages.InvalidUser);
          break;
        case 'invalid_response':
          setErrorMessage(ErrorMessages.InvalidResponse);
          break;
        default:
          setErrorMessage(ErrorMessages.InvalidUser);
          break;
      }
    } else if (refresh && access) {
      searchParams.delete('code');
      searchParams.delete('state');
      setSearchParams(searchParams);
      storeAuthenticationObject({ access, refresh });
      navigate(`/application/strategy`);
    } else if (isSignedIn) {
      userInfoQuery.refetch();
    }
  }, [searchParams, setSearchParams, navigate, userInfoQuery, isSignedIn]);

  const checkAndSetEmail = async (email: string): Promise<void> => {
    if (checkValidEmail(email)) {
      const domainCheckResult = await checkDomain(getEmailDomain(email));
      setErrorMessage('');
      if (
        domainCheckResult['url'] &&
        domainCheckResult['url'] !== '' &&
        domainCheckResult['authentication_method'] != 'password'
      ) {
        window.location.href = domainCheckResult['url'];
      } else {
        setShouldShowPassword(true);
      }
    } else {
      setShouldShowPassword(false);
      setErrorMessage(ErrorMessages.ValidEmailRequired);
    }
    setEmail(email);
  };

  const handleSendResetPasswordLink = useMutation(
    ['sendResetPassword', email],
    () => (email ? sendResetPasswordLink(email) : Promise.reject('Email is required!')),
    {
      onSettled: () => setCurrentView(ViewName.SentResetPasswordLink),
    }
  );

  const returnToSignInView = () => {
    // Clear out email, password field as we return from the forgot password email sent view
    setEmail('');
    setPassword('');
    setCurrentView(ViewName.SignIn);
  };

  const signInViews = {
    [ViewName.SignIn]: (
      <SignInView
        email={email}
        shouldShowPassword={shouldShowPassword}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        handleLogin={handleLogin}
        handleSignUp={() => {
          if (showNewOnboarding) {
            return navigate('/sign-up', { replace: true });
          }

          return email && password ? handleSignup() : setCurrentView(ViewName.SignUp);
        }}
        handleResetPassword={() => {
          /* When user enters the view where he has to type the email to send password reset link
             clear all the error messages, else the box appears to be red along with the text
          */
          setErrorMessage('');
          setCurrentView(ViewName.SendResetPasswordLink);
        }}
        error={errorMessage}
      />
    ),
    [ViewName.SignUp]: (
      <SignUpView
        email={email}
        setEmail={setEmail}
        password={password}
        confirmPassword={confirmPassword}
        setPassword={setPassword}
        setConfirmPassword={setConfirmPassword}
        error={errorMessage}
        handleSignUp={handleSignup}
      />
    ),
    [ViewName.SendResetPasswordLink]: (
      <SendResetPasswordLinkView
        email={email}
        setEmail={setEmail}
        handleSendResetPasswordLink={() => handleSendResetPasswordLink.mutate()}
        error={errorMessage}
      />
    ),
    [ViewName.SentResetPasswordLink]: <SentResetPasswordLinkView email={email} handleChangeView={returnToSignInView} />,
    [ViewName.ResetPassword]: <ResetPasswordView handleChangeView={setCurrentView} />,
    [ViewName.ResetPasswordSuccessful]: (
      <SignInView
        title="Success! Please sign in to continue..."
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        handleLogin={handleLogin}
        handleSignUp={() => setCurrentView(ViewName.SignUp)}
        handleResetPassword={() => setCurrentView(ViewName.SendResetPasswordLink)}
        error={errorMessage}
      />
    ),
  };

  const loginQuery = useQuery(['login', { email, password }], () => login({ email, password }), {
    enabled: false,
  });

  async function handleLogin(isSignup = false) {
    setErrorMessage(!email || !password ? ErrorMessages.CredentialsRequired : '');
    if (!shouldShowPassword && !isSignup) {
      await checkAndSetEmail(email);
      return;
    }
    if (!email || !password) {
      return;
    }

    const { data } = await loginQuery.refetch();

    if (!data) {
      setErrorMessage(ErrorMessages.InvalidCredentials);
      return;
    }

    const { access, refresh } = data;

    if (access) {
      storeAuthenticationObject({ access, refresh });
      await userInfoQuery.refetch();
    } else if (showNewOnboarding && data?.detail === BackendError.OTPNotVerified) {
      setCredentials(email, password);
      setSignUpStep(SignUpStep.CheckYourEmail);
      navigate('/sign-up', { replace: true });
    }

    setErrorMessage(data?.detail || ErrorMessages.InvalidCredentials);
  }

  const signUp = useMutation(['signup', { email, password }], () => signup({ email, password }), {
    onSuccess: () => {
      handleLogin(true);
    },
    onError: ({ error }: { error: any }) => {
      const message = error.email?.pop() || error.password?.pop() || ErrorMessages.Default;
      setErrorMessage(message);
      return;
    },
  });

  async function handleSignup() {
    setErrorMessage(!email || !password ? ErrorMessages.CredentialsRequired : '');
    if (!email || !password) {
      return;
    }
    trackEvent(AnalyticsEventType.SignUpTapped, { userContext: { email: email } });
    signUp.mutate();
  }

  return (
    <div id={styles.pageContainer}>
      {currentView != ViewName.SignUp && (
        <div id={styles.signInContainer}>
          <div style={{ flex: 1, zIndex: 2, opacity: '100%' }}>
            {signInViews[currentView as keyof typeof signInViews]}
          </div>
          <div>
            <p id={styles.legal}>{`© ${new Date().getFullYear()} Bloomfilter. All rights reserved.`}</p>
          </div>
        </div>
      )}
      {currentView === ViewName.SignUp && <>{signInViews[currentView as keyof typeof signInViews]}</>}
    </div>
  );
}

type PasswordProps = {
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  formLabel?: string;
  formPlaceholder?: string;
  error?: string | boolean;
};

export function Password({ password, setPassword, onKeyDown, formLabel, formPlaceholder, error }: PasswordProps) {
  const [showPassword, setShowPassword] = useState(false);

  const showHidePassword = (
    <div onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
      <Icon
        icon={showPassword ? 'ant-design:eye-invisible-filled' : 'ant-design:eye-outlined'}
        width={16}
        height={16}
        color={newCOLORS.white}
      />
    </div>
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  return (
    <TextInput
      label={formLabel || 'Password'}
      placeholder={formPlaceholder || 'Type password'}
      value={password}
      onChange={(e: any) => setPassword(e.target.value)}
      onKeyDown={handleKeyDown}
      error={error}
      type={showPassword ? 'text' : 'password'}
      rightSection={showHidePassword}
      classNames={{ input: styles.textInput, label: styles.textInputLabel }}
    />
  );
}

type ConfirmPasswordProps = {
  confirmPassword: string;
  setConfirmPassword: React.Dispatch<React.SetStateAction<string>>;
  passwordsMatch: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  error?: string | boolean;
};

export function ConfirmPassword({
  confirmPassword,
  setConfirmPassword,
  passwordsMatch,
  onKeyDown,
  error,
}: ConfirmPasswordProps) {
  const [passwordtouch, setPasswordTouched] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    if (!passwordtouch) {
      setPasswordTouched(true);
    }
  };

  const getIcon = () => {
    if (!passwordtouch) {
      return null;
    } else {
      return passwordsMatch ? (
        <img width={14} height={14} src={icons.validationSuccess} alt="Success" />
      ) : (
        <img width={14} height={14} src={icons.validationFail} alt="Fail" />
      );
    }
  };

  const showHidePassword = (
    <div onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
      <Icon
        icon={showPassword ? 'ant-design:eye-invisible-filled' : 'ant-design:eye-outlined'}
        width={16}
        height={16}
        color={newCOLORS.white}
      />
    </div>
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  return (
    <div className={styles.inputContainer}>
      <div className={styles.labelWithIcon}>
        <label className={styles.textInputLabel}>Confirm Password {getIcon()}</label>
      </div>
      <TextInput
        value={confirmPassword}
        onChange={handleChange}
        error={error}
        type={showPassword ? 'text' : 'password'}
        placeholder="Re-type password"
        classNames={{ input: styles.textInput, label: styles.textInputLabel }}
        onKeyDown={handleKeyDown}
        rightSection={showHidePassword}
      />
    </div>
  );
}
