import { Button, TextInput } from '@mantine/core';
import { Dispatch, MouseEventHandler, SetStateAction } from 'react';

import { Icon } from '@iconify/react/dist/iconify.js';
import { Brand } from '../../../components/brand/brand';
import { newCOLORS } from '../../../styles/colors';
import { Password } from '../sign-in';
import styles from '../sign-in.module.css';

type SignInViewProps = {
  title?: string;
  email: string;
  shouldShowPassword?: boolean;
  setEmail: any;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  handleLogin: any;
  handleSignUp?: MouseEventHandler;
  handleResetPassword: MouseEventHandler;
  error: string;
};

const showNewOnboarding = import.meta.env.VITE_FEATURE_FLAG_ONBOARDING_V2 === 'true';

export function SignInView({
  title,
  email,
  setEmail,
  password,
  setPassword,
  shouldShowPassword,
  handleLogin,
  handleSignUp,
  handleResetPassword,
  error,
}: SignInViewProps) {
  function handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      handleLogin(false);
    }
  }

  return (
    <div style={{ display: 'grid', gridTemplateRows: '1fr 1fr 1fr', height: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Brand style={{ minWidth: '60%' }} darkMode={true} clickable={false} />
        <h2 style={{ textAlign: 'center', color: newCOLORS.white }}>{title}</h2>
      </div>
      <div>
        <div id={styles.formContainer}>
          <TextInput
            autoFocus
            label="Email"
            type="email"
            value={email}
            classNames={{ input: styles.textInput, label: styles.textInputLabel }}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            error={!!error}
          />
          {shouldShowPassword ? (
            <div className={styles.passwordContainer}>
              <Password password={password} setPassword={setPassword} error={error} onKeyDown={handleKeyDown} />
              <a className={styles.link} onClick={handleResetPassword} style={{ alignSelf: 'flex-end' }}>
                Forgot Password?
              </a>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
        <Button className={styles.button} onClick={() => handleLogin(false)}>
          {shouldShowPassword ? (
            <div>
              Sign In <Icon style={{ paddingLeft: 5 }} icon={'bi:arrow-right'} />
            </div>
          ) : (
            <div>
              Check User <Icon style={{ paddingLeft: 5 }} icon={'bi:arrow-right'} />
            </div>
          )}
        </Button>
        {showNewOnboarding && (
          <a className={styles.link} onClick={handleSignUp} style={{ alignSelf: 'center' }}>
            Sign Up
          </a>
        )}
      </div>
    </div>
  );
}
