import { useQuery } from '@tanstack/react-query';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Issue } from '../../api/issues-client/issues-client.class';
import { getPortfolioMetrics } from '../../api/portfolio-client/portfolio-client';
import { PortfolioIssue, PortfolioMetrics } from '../../api/portfolio-client/portfolio-client.type';
import { Project } from '../../api/projects-client/projects-client.type';
import { fetchPortfolioProjects } from '../../api/summary-client/summary-client';
import { fetchUserInfo } from '../../api/user-client/user-client';
import { HealthGaugeCard } from '../../components/health-gauge-card/health-gauge-card';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { ProjectCard } from '../../components/project-card/project-card';
import { ProjectContext, UserContext } from '../../contexts';
import { filterSubprojects } from '../../contexts/project.helpers';
import { trackEvent } from '../../helpers/analytics-event/analytics-event';
import { AnalyticsDimensionsProps, AnalyticsEventType } from '../../helpers/analytics-event/analytics-event.type';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { logout } from '../../helpers/storage/storage';
import { useGlobalStore } from '../../store/global-store/global-store';
import { PageHeader } from '../../styles/new-shared-styled-components/new-shared-styled-components';
import { SubpageHeadingTag } from '../../styles/shared-styled-components';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';

export const Dashboard = () => {
  useDocumentTitle('Dashboard - Bloomfilter');
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const { project, setProject, setSubprojects } = useContext(ProjectContext);
  const ref = useRef<any>(null);
  const [selectedPortfolio, setSelectedPortfolio] = useState<string>('');
  const [projectList, setProjectList] = useState([] as Project[]);
  const [averageHealth, setAverageHealth] = useState<number>(100);
  const [metrics, setMetrics] = useState([] as PortfolioIssue[]);

  const portfolio = useGlobalStore((state) => state.portfolio);
  const portfolios = useGlobalStore((state) => state.portfolios);

  useEffect(() => {
    if (portfolios.length > 0) {
      setSelectedPortfolio(portfolio ? portfolio.id : portfolios[0].id);
    }
  }, [portfolio, portfolios]);

  /**
   * React query to handle setting of projects list
   */
  useQuery(
    ['portfolio-projects', selectedPortfolio],
    () => {
      return selectedPortfolio === ''
        ? Promise.reject(new Error('Invalid Portfolio ID'))
        : fetchPortfolioProjects(selectedPortfolio);
    },
    {
      enabled: !!selectedPortfolio,
      onSuccess: setProjectList,
      onError: (error: any) => {
        const errorState = { errorMessage: error?.statusText, errorStatus: error?.status };
        navigate(`/application/out-of-bloom`, { state: errorState });
      },
    }
  );

  useQuery(['UserInfo', user], user ? () => Promise.resolve(user) : fetchUserInfo, {
    enabled: !!user,
    onSuccess(data) {
      trackEvent(AnalyticsEventType.PortfolioViewed, { userContext: user });
      setUser(data);
    },
    onError: logout,
  });

  /**
   * React query to handle the setting of portfolio metrics
   */
  const portfolioMetricsQuery = useQuery(
    ['getPortfolioMetrics', selectedPortfolio],
    () => {
      return selectedPortfolio === ''
        ? Promise.reject(new Error('Invalid Portfolio ID'))
        : getPortfolioMetrics(selectedPortfolio);
    },
    {
      // only run when we have finished fetching user data
      enabled: !!selectedPortfolio,
      onSuccess: (data: PortfolioMetrics) => {
        const portfolioIssues = formatProjectMetricsData(data);
        setAverageHealth(data.health);
        setMetrics(portfolioIssues);
      },
      onError: () => {
        console.error('There was an issue fetching project metrics.');
        setAverageHealth(100);
        setMetrics([]);
      },
    }
  );

  const projectClick = (project: Project) => {
    const name = project.name;
    const props: AnalyticsDimensionsProps = {
      userContext: user,
      project: { name },
    };
    trackEvent(AnalyticsEventType.ProjectTapped, props);
    const filteredProject = filterSubprojects(project);

    setSubprojects(filteredProject.subprojects);
    setProject(filteredProject as Project);
    navigate(`/application/project/${project.id}`, { state: { project } });
  };

  return (
    <Fragment>
      <BrandedLoadingOverlay
        visible={!project && portfolioMetricsQuery.isLoading}
        transitionDuration={30}
        variant="colored"
      />

      <MenuDrivenContainer header={portfolio && <PageHeader>{portfolio.name} Overview</PageHeader>}>
        <div ref={ref}>
          <HealthGaugeCard
            heading="Portfolio Performance Score"
            tooltip="The Portfolio Performance Score provides an overall assessment of projects, considering each project’s Project Performance Score and the number of developers on that project."
            health={averageHealth}
            context="portfolio"
            issues={(metrics || []).map(
              (metric) => new Issue(metric.id, metric.text, metric.text, metric.trend as string, metric.tooltip)
            )}
            shouldAnimate={averageHealth !== 0}
            status={portfolioMetricsQuery.status}
            delay
          />
          {projectList.length ? (
            <Fragment>
              <SubpageHeadingTag>Projects within your portfolio</SubpageHeadingTag>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '2em' }}>
                {projectList.map((project: Project) => {
                  return <ProjectCard project={project} onClick={projectClick} key={project.id} />;
                })}
              </div>
            </Fragment>
          ) : null}
        </div>
      </MenuDrivenContainer>
    </Fragment>
  );
};

/**
 * Transforms the given portfolio metrics data into an array of portfolio issues.
 *
 * @param {PortfolioMetrics} metricsData - An object containing various portfolio metrics, such as portfolio health, average cycle time, and at-risk projects count.
 * @returns {PortfolioIssue[]} - An array of portfolio issues, where each issue includes an id, text describing the issue, and a status indicating the severity of the issue.
 */

export function formatProjectMetricsData(metricsData: PortfolioMetrics): PortfolioIssue[] {
  // grammar-check disable-next-line ID_CASING
  const METRICS_DICT: { [metricName: string]: Omit<PortfolioIssue, 'id'> } = {
    improving_projects_count: {
      text: 'projects trending positively',
      tooltip:
        'Sum of projects in the portfolio where the average Sprint Performance Score is higher for the two most recently completed sprints when compared to the two sprints prior to the two most recent.',
    },
    average_cycle_time: {
      text: 'days average cycle time',
      tooltip: 'Average time, in days, between starting a task and completing the task, for all portfolio projects.',
    },
    at_risk_projects_count: {
      text: 'projects trending negatively',
      tooltip:
        'Sum of projects in the portfolio where the average Sprint Performance Score is lower for the two most recently completed sprints when compared to the two sprints prior to the two most recent.',
    },
    current_at_risk_sprints_count: {
      text: 'active sprints C or worse',
      tooltip:
        'Sum of sprints in the portfolio where the current Sprint Performance Score is a C or worse indicating risk to meeting the sprint goal.',
    },
    average_work_item_age: {
      text: 'average work item age in days',
      tooltip:
        'Average number of days, since task creation, to either: task completion, if completed; or current day for all tasks in an active sprint, for all portfolio projects.',
    },
    total_throughput: {
      text: 'total throughput',
      tooltip: 'Sum of the tasks completed in the last completed sprint, for all portfolio projects.',
    },
  };

  return Object.entries(metricsData).reduce((formattedMetrics, [metricName, value], index) => {
    if (metricName in METRICS_DICT) {
      formattedMetrics.push({
        id: index.toString(),
        text: `${value} ${METRICS_DICT[metricName].text}`,
        tooltip: METRICS_DICT[metricName].tooltip,
      });
    }
    return formattedMetrics;
  }, [] as { id: string; text: string; tooltip: string }[]);
}
